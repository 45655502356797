/**
 * Basic color palette
 * @type {Object}
 */
export const COLORS = {
  BLACK: '#000000',
  BLACK_15: 'rgba(0,0,0,.15)',
  BLACK_30: 'rgba(0,0,0,.3)',
  BLACK_50: 'rgba(0,0,0,.5)',
  DARKER_GRAY: '#72767a',
  DARKEST_GRAY: '#333333',
  DARK_GRAY: '#a2a8ad',
  GRAY: '#a2a8ad',
  GREEN: '#669966',
  LIGHTER_GRAY: '#e6e9ed',
  LIGHTEST_GRAY: '#f7f9fc',
  LIGHT_GRAY: '#d2d8de',
  PRIMARY: '#144B3C',
  PRIMARY_ACTIVE: '#0A7256',
  WHITE: '#ffffff',
  WHITE_15: 'rgba(255,255,255,.15)',
  WHITE_30: 'rgba(255,255,255,.3)',
  WHITE_50: 'rgba(255,255,255,.5)',
  RED: '#DA4453',
  /** Color Filter  */
  SWATCH_PURPLE: '#96c',
  SWATCH_PINK: '#f9c',
  SWATCH_YELLOW: '#ff6',
  // SWATCH_RED: COLORS.RED,
  SWATCH_ORANGE: '#FFCC66',
  SWATCH_GREEN: '#99CC66',
  SWATCH_BLUE: '#99CCFF',
  TEXT_MUTED: '#999999',
  TEXT_MUTED_2: '#666666',
};

export const FONT_COLOR = COLORS.DARKEST_GRAY;
