import React, { useState, useEffect, useCallback } from 'react';
import AnalyticsContext, {
  AnalyticsContextInterface,
} from './AnalyticsContext';
import { Props } from './AnalyticsProvider.d';
import useUser from '@/hooks/useUser';
import { isAnalyticsSuppressed } from '@/utils/cookies';

const nodeEnv = process.env.NODE_ENV;
const showAnalyticsConsole = Boolean(
  process.env.NEXT_PUBLIC_DEV_SHOW_ANALYTICS_CONSOLE
);

const AnalyticsProvider = ({ children }: Props): JSX.Element => {
  const user = useUser();
  const [delayedEvents, setDelayedEvents] = useState([]);

  const track = useCallback(
    (event, properties) => {
      const eventProperties = {
        ...properties,
        app_name: 'React App',
        is_subscriber: Boolean(user.is_subscriber),
      };

      if (user.isPending) {
        return setDelayedEvents(delayedEvents => [
          ...delayedEvents,
          { name: event, eventProperties },
        ]);
      }

      if (window.analytics && !isAnalyticsSuppressed()) {
        if (nodeEnv != 'production' && showAnalyticsConsole) {
          /* eslint-disable-next-line no-console, prefer-rest-params */
          console.info('Segment Track Event:', event, eventProperties);
        }

        window.analytics.track(event, eventProperties);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user.isPending]
  );

  const page = useCallback(
    (event, properties) => {
      const eventProperties = {
        ...properties,
        app_name: 'React App',
        is_subscriber: Boolean(user.is_subscriber),
      };

      if (user.isPending) {
        return setDelayedEvents(delayedEvents => [
          ...delayedEvents,
          { page: event, eventProperties },
        ]);
      }

      if (window.analytics && !isAnalyticsSuppressed()) {
        if (nodeEnv != 'production' && showAnalyticsConsole) {
          /* eslint-disable-next-line no-console, prefer-rest-params */
          console.info('Segment Page Event:', event, eventProperties);
        }

        window.analytics.page(event, eventProperties);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user.isPending]
  );

  useEffect(() => {
    if (!user.isPending && delayedEvents.length) {
      delayedEvents.forEach(event => {
        if (event.name) track(event.name, event.eventProperties);
        if (event.page) page(event.page, event.eventProperties);
      });
      setDelayedEvents([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayedEvents.length, user.isPending]);

  const context: AnalyticsContextInterface = {
    track,
    page,
  };

  return (
    <AnalyticsContext.Provider value={context}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
