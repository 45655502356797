// Because of some Next configurations when using some env in the browser make sure to use the whole `process.env.*`
export const ENV: any = process?.env;
export const NODE_ENV: string = process.env.NODE_ENV;
export const IS_DEVELOPMENT: boolean = NODE_ENV === 'development';
export const IS_PREVIEW: boolean =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';
// VERSION env variable is set by Jenkinsfile deployment script
export const VERSION: string = process.env.VERSION;
export const SF_URL: string = process.env.NEXT_PUBLIC_SF_URL;
export const SF_API_BASE: string = process.env.NEXT_PUBLIC_SF_API_BASE;
export const SF_API_V3 = `${SF_API_BASE}/v3`;
export const CDN_URL: string = process.env.NEXT_PUBLIC_CDN_URL;
export const TARGET_ENV: string = process.env.TARGET_ENV;
export const ENABLE_SW: string = process.env.NEXT_PUBLIC_ENABLE_SW;
export const SERVICE_WORKER_ON = ENABLE_SW === '1';
export const SEGMENT_API_KEY: string = process.env.NEXT_PUBLIC_SEGMENT_API_KEY;
export const NEWRELIC_AGENTID: string =
  process.env.NEXT_PUBLIC_NEWRELIC_AGENTID;
export const NEWRELIC_APPLICATIONID: string =
  process.env.NEXT_PUBLIC_NEWRELIC_APPLICATIONID;
export const SENTRY_DSN: string = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const SHIPPING_SAVINGS_PRICE: string = process.env.NEXT_PUBLIC_SHIPPING_SAVINGS_PRICE;
